<template>
  <v-col cols="12">
    <crud-filho-list
      :mostra-tabela="!formulario"
      :headers="headers"
      :items="items"
      :paginacao.sync="paginacao"
      :total-registros="totalRegistros"
      :loading="loading"
      :hide-headers="$vuetify.breakpoint.xsOnly"
      @novo-event="novo"
    >
      <template
        slot="items"
        slot-scope="{item}"
      >
        <tr>
          <td
            v-if="mostrarImagens"
            style="width: 128px;"
          >
            <imagem-view-carrossel :imagens-str="item.item.imagens" />
          </td>
          <td v-if="$vuetify.breakpoint.xsOnly">
            <v-icon
              small 
              @click="editar(item.item)"
            >
              mdi-pencil
            </v-icon>
            {{ item.item.nomeItem }}
            <br>
            <b class="mr-3">
              {{ $t('EventoItens.quantidade') }}
            </b>{{ parseFloat(item.item.quantidade) }}
            <br><b class="mr-3">
              {{ $t('EventoItens.valorTotalItem') }}
            </b><span v-money="{simbolo:'R$', valor:item.item.valorTotalItem}" />
          </td>
          <td
            v-if="$vuetify.breakpoint.smAndUp"
            class="justify-left btnEditar cursorPointer"
            @click="editar(item.item)"
          >
            <v-icon
              small
            >
              mdi-pencil
            </v-icon>
            {{ item.item.nomeItem }}
          </td>
          <td
            v-if="$vuetify.breakpoint.smAndUp"
            class="justify-center"
          >
            {{ parseFloat(item.item.quantidade) }}
          </td>
          <td
            v-if="$vuetify.breakpoint.smAndUp"
            v-money="{simbolo:'R$', valor:item.item.valorTotalItem}"
            class="justify-center"
          />
          <td v-if="evento && evento.itemComputado">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="v-btn--simple"
                  icon
                  @click="computarItem(item.item)"
                  v-on="on"
                >
                  <v-icon>mdi-package-variant</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('EventoItens.computarItem') }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
      <template slot="antesDoBotaoAdicionar">
        <v-col
          v-if="$vuetify.breakpoint.mdAndUp"
          cols="10"
        >
          <v-chip
            color="primary"
            label
            text-color="white"
            :ripple="false"
            class="mr-4"
          >
            <v-icon left>
              mdi-cash
            </v-icon>
            <span class="pr-1">
              {{ $t('EventoItens.valorTotalEvento') }}:
            </span>
            <span v-money="{simbolo:'R$', valor: valorTotalEvento}" />
          </v-chip>
          <v-chip
            v-if="!visaoCliente"
            color="tertiary"
            label
            text-color="white"
            :ripple="false"
            :class="{'mt-2': $vuetify.breakpoint.smAndDown}"
          >
            <v-icon left>
              mdi-cash
            </v-icon>
            <span class="pr-1">
              {{ $t('EventoItens.valorCustoEvento') }}:
            </span>
            <span v-money="{simbolo:'R$', valor: valorCustoEvento}" />
          </v-chip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="v-btn--simple"
                icon
                color="tertiary"
                v-bind="attrs"
                @click="processaVisaoCliente()"
                v-on="on"
              >
                <v-icon>{{ visaoCliente? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t( 'EventoItens.visao_cliente' ) }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="v-btn--simple"
                icon
                color="red"
                v-bind="attrs"
                @click="mostrarImagens = !mostrarImagens"
                v-on="on"
              >
                <v-icon>{{ mostrarImagens? 'mdi-image-off-outline' : 'mdi-image-outline' }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t( !mostrarImagens? 'padrao_mostra_imagens' : 'padrao_esconde_imagens' ) }}</span>
          </v-tooltip>
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.smAndDown"
          cols="12"
        >
          <v-chip
            color="primary"
            label
            text-color="white"
            :ripple="false"
          >
            <v-icon left>
              mdi-cash
            </v-icon>
            <span class="pr-1">
              {{ $t('EventoItens.valorTotalEvento') }}:
            </span>
            <span v-money="{simbolo:'R$', valor: valorTotalEvento}" />
          </v-chip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="v-btn--simple"
                icon
                color="tertiary"
                v-bind="attrs"
                @click="processaVisaoCliente()"
                v-on="on"
              >
                <v-icon>{{ visaoCliente? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t( 'EventoItens.visao_cliente' ) }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="v-btn--simple"
                icon
                color="red"
                v-bind="attrs"
                @click="mostrarImagens = !mostrarImagens"
                v-on="on"
              >
                <v-icon>{{ mostrarImagens? 'mdi-image-off-outline' : 'mdi-image-outline' }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t( !mostrarImagens? 'padrao_mostra_imagens' : 'padrao_esconde_imagens' ) }}</span>
          </v-tooltip>
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.smAndDown && !visaoCliente"
          cols="12"
        >
          <v-chip
            color="tertiary"
            label
            text-color="white"
            :ripple="false"
          >
            <v-icon left>
              mdi-cash
            </v-icon>
            <span class="pr-1">
              {{ $t('EventoItens.valorCustoEvento') }}:
            </span>
            <span v-money="{simbolo:'R$', valor: valorCustoEvento}" />
          </v-chip>
        </v-col>
      </template>
    </crud-filho-list>
    <crud-filho-form
      :mostra-formulario="formulario"
      :is-editting="isEditting"
      :mensagem-pergunta-remocao="$t('padrao_deseja_remover_registro')"
      :alerta-validacao="alertValidacao"
      :mensagem-erro="error"
      :mostra-botao-salvar-continuar="false"
      @closeDialogEvent="closeDialog"
      @saveEvent="saveEvent"
      @save-continue-event="saveEvent"
      @deleteEvent="remover"
    >
      <v-container class="py-0">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="autoCompleteModel"
              :items="autoCompleteItens"
              :loading="autoCompleteLoading"
              :search-input.sync="autoCompleteCampoBusca"
              :filter="filterAc"
              :label="$t('EventoItens.item')"
              hide-no-data
              item-text="nomeItem"
              item-value="id"
              :placeholder="$t('padrao_digite_para_buscar')"
              prepend-icon="mdi-database-search"
              return-object
            >
              <template v-slot:item="{item}">
                <!--
                <imagem-view-carrossel
                  :imagens-str="item.imagens"
                  :mostra-botoes="false"
                />
                {{ item.nomeItem }}
                <v-list-item-content>
                  <imagem-view-carrossel
                    :imagens-str="item.imagens"
                    :mostra-botoes="false"
                  />
                  <v-list-item-title>{{ item.nomeItem }}</v-list-item-title>
                  <v-list-item-subtitle v-money="{simbolo:'R$', valor:item.valorVenda}" />
                </v-list-item-content> -->
                <v-list-item-avatar
                  v-if="item.imagens"
                  size="100"
                >
                  <imagem-view-carrossel
                    :imagens-str="item.imagens"
                    :mostra-botoes="false"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.nomeItem }}</v-list-item-title>
                  <v-list-item-subtitle v-money="{simbolo:'R$', valor:item.valorVenda}" />
                </v-list-item-content> 
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="6"
            md="6"
          >
            <v-text-field
              v-model="objEditar.quantidade"
              :rules="[required]"
              required
              prepend-icon="mdi-counter"
              :label="$t('EventoItens.quantidade')"
              type="number"
            />
          </v-col>
          <v-col
            v-if="mostraQuantidadeEstoque"
            cols="12"
          >
            <v-data-table
              v-model="itemDepositoSelecionado"
              :headers="cabecalhoItemDeposito"
              item-key="idDeposito"
              :items="itemDepositoEstoqueLista"
              :single-select="true"
              show-select
            >
              <template v-slot:item.data-table-select="{ isSelected, item, select }">
                <v-checkbox
                  v-if="item.idDeposito"
                  v-model="isSelected"
                  :ripple="true"
                  color="green"
                  @click="processaClick(isSelected,select,$event, item)"
                />
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="valorUnitario"
              disabled
              color="primary"
              prepend-icon="mdi-cash"
              :label="$t('EventoItens.valorUnitario')"
              type="number"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="objEditar.desconto"
              :label="$t('EventoItens.desconto')"
              type="number"
            />
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model="objEditar.valorTotalItem"
              :label="$t('EventoItens.valorTotalItem')"
              readonly
            />
          </v-col>

          <v-col cols="12">
            <v-switch
              v-model="objEditar.itemReposicao"
              :label="$t('EventoItens.itemReposicao')"
              class="purple-input"
              prepend-icon="mdi-playlist-check"
              @change="changeItemReposicao"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="objEditar.observacao"
              outlined
              name="observacao"
              :label="$t('EventoItens.observacao')"
              clearable
            />
          </v-col>
        </v-row>
      </v-container>
    </crud-filho-form>
  </v-col>
</template>

<script>

  import ItemService from '@/services/ItemService'
  import EventoItensService from '@/services/EventoItensService'
  import EventosService from '../services/EventosService'
  import EstoqueService from '../services/EstoqueService'
  import EmpresaClientesService from '../services/EmpresaClientesService'

  export default {
    props: {
      idEvento: {
        type: Number,
        default: 0
      },
      idEmpresaCliente: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        alertValidacao: false,
        autoCompleteModel: {},
        autoCompleteItens: [],
        autoCompleteLoading: false,
        autoCompleteCampoBusca: '',
        loadingEndereco: false,
        valorUnitario: 0,
        valorTotalEvento: 0,
        valorCustoEvento: 0,

        visaoCliente: false,

        mostrarImagens: false,

        mostraQuantidadeEstoque: false,
        itemDepositoEstoqueLista: [],
        itemDepositoSelecionado: [],

        items: [],
        tempItems: [],
        paginacao: null,
        loading: false,
        totalRegistros: 0,

        evento: {},

        objPadrao: {
          idItem: null,
          idEvento: null,
          quantidade: null,
          desconto: 0,
          valorTotalItem: 0,
          itemReposicao: false,
          observacao: '',
          idDeposito: null
        },
        objEditar: {
          idItem: null,
          idEvento: null,
          quantidade: null,
          desconto: 0,
          valorTotalItem: 0,
          itemReposicao: false,
          observacao: '',
          idDeposito: null
        },
        isEditting: false,
        error: null,
        required: value => !!value || 'Campo Obrigatório',
        formulario: false
      }
    },
    computed: {
      headers () {
        const cabecalhos = [
          {
            text: this.$i18n.t('EventoItens.item'),
            value: 'nomeItem',
            sortable: false
          },
          {
            text: this.$i18n.t('EventoItens.quantidade'),
            value: 'quantidade',
            sortable: false
          },
          {
            text: this.$i18n.t('EventoItens.valorTotalItem'),
            value: 'valorTotalItem',
            sortable: false
          }
        ]

        if(this.mostrarImagens){
          cabecalhos.unshift({
            text: null,
            value: 'images',
            sortable: false
          })
        }

        if(this.evento && this.evento.eventoComputado){
          cabecalhos.push({
            text: '',
            value: '',
            sortable: false
          })
        }

        return cabecalhos

      },
      cabecalhoItemDeposito () {
        return [
          {
            text: this.$i18n.t('EventoItens.idDeposito'),
            value: 'nomeDeposito',
            sortable: false
          },
          {
            text: this.$i18n.t('EventoItens.quantidadeEstoque'),
            value: 'quantidadeEstoque',
            sortable: false
          },
          {
            text: this.$i18n.t('EventoItens.quantidadeBloqueada'),
            value: 'quantidadeBloqueada',
            sortable: false
          },
          {
            text: this.$i18n.t('EventoItens.quantidadeDisponivel'),
            value: 'quantidadeDisponivel',
            sortable: false
          }
        ]
      }

    },
    watch: {
      'objEditar.quantidade' (val) {
        this.processaQuantidade(val)
      },
      'objEditar.desconto' (val) {
        if (!val) {
          val = ''
        }
        val = val.replace(',', '.')
        let quantidade = this.objEditar.quantidade
        if (!quantidade) {
          quantidade = ''
        }
        quantidade = quantidade.replace(',', '.')
        let valorFinal = this.valorUnitario * quantidade
        if (!isNaN(val)) {
          let desconto = Number(val)
          if (desconto >= 0 && this.objEditar.valorTotalItem > 0 && this.objEditar.valorTotalItem > desconto) {
            this.objEditar.valorTotalItem = valorFinal - desconto
          }
        } else {
          this.objEditar.valorTotalItem = valorFinal
        }
      },
      idEvento (val) {
        if (val > 0) {
          this.buscar()
          this.buscarDadosEvento()
        }
      },

      autoCompleteCampoBusca (val) {
        if (!val) {
          return
        }
        if (val.length < 3) {
          return
        }
        this.buscaItem(val)
      },
      autoCompleteModel (val) {
        if (val === null) {
          return
        }
        if(this.objEditar.Item !== val){
          if(this.objEditar.itemReposicao){
            this.valorUnitario = val.valorReposicao || 0
          }else{
            this.valorUnitario = val.valorVenda || 0
          }
        }

        this.objEditar.Item = val
        this.objEditar.idItem = val.id

        this.processaQuantidade(this.objEditar.quantidade)
          
        this.buscaValoresEstoque(val)
      }

    },
    mounted () {
      if (this.idEvento > 0) {
        this.buscar()
        this.buscarDadosEvento()
      }
    },
    methods: {

      processaQuantidade(val){
        if (!val) {
          val = ''
        }
        if((typeof val) === 'string'){
          val = val.replace(',', '.')
        }
        if (!isNaN(val)) {
          let quantidade = Number(val)
          if (quantidade > 0) {
            this.objEditar.valorTotalItem = (this.valorUnitario * quantidade) - parseFloat(!isNaN(this.objEditar.desconto) ? this.objEditar.desconto : 0)
          } else {
            this.objEditar.valorTotalItem = 0
            this.objEditar.desconto = 0
          }
        } else {
          this.objEditar.valorTotalItem = 0
          this.objEditar.desconto = 0
        }
      },

      buscarDadosEvento() {
        EventosService.findById({id: this.idEvento})
          .then( res => {
            this.evento = res.data
          })
      },

      computarItem(item) {

      },

      changeItemReposicao(){
        if(this.objEditar.itemReposicao){
          if( this.autoCompleteModel){
            this.valorUnitario = this.autoCompleteModel.valorReposicao || 0
          }else{
            this.valorUnitario = 0
            this.objEditar.valorTotalItem = 0
          }
        }else{
          if( this.autoCompleteModel){
            this.valorUnitario = this.autoCompleteModel.valorVenda || 0
          }else{
            this.valorUnitario = 0
            this.objEditar.valorTotalItem = 0
          }
        }
        this.processaQuantidade(this.objEditar.quantidade)
      },

      processaClick(isSelected, select, event, item){
        if(!isSelected){
          this.itemDepositoSelecionado = []
        } else {
          this.itemDepositoSelecionado = [item]
        }
        select(event)
      },

      async validaQuantidadeEstoque(){

        if(this.autoCompleteModel === null) {
          return 'R'
        }

        if(!this.autoCompleteModel.controlaEstoque){
          return 'A'
        }

        if(this.idEmpresaCliente <= 0){
          return 'A'
        }else{
          const empresaCliente = await EmpresaClientesService.findById(this.idEmpresaCliente)

          if(!this.itemDepositoSelecionado || this.itemDepositoSelecionado.length === 0){
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoTitulo: this.$i18n.t('padrao_alerta'),
              dialogoDescricao: this.$i18n.t('EventoItens.selecioneDeposito')
            })
            return 'P'
          }


          if(this.objEditar.itemReposicao){
            return 'A'
          }

          const depositoSelecionado = this.itemDepositoSelecionado[0]

          const quantidadeFinal = depositoSelecionado.quantidadeDisponivel - parseFloat(this.objEditar.quantidade)

          if(quantidadeFinal >= 0){
            return 'A'
          }

          if(empresaCliente.data.acaoSemEstoque === 'N'){
            return 'A'
          }

          if(empresaCliente.data.acaoSemEstoque === 'A'){
            this.$root.$emit('mostra_dialogo_alerta', {
              dialogoTitulo: this.$i18n.t('padrao_alerta'),
              dialogoDescricao: this.$i18n.t('EventoItens.alertaQuantidadeSelecionada'),
              funcaoRetorno: this.continueSaveEvent
            })
            return 'P'
          } else {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoTitulo: this.$i18n.t('padrao_alerta'),
              dialogoDescricao: this.$i18n.t('EventoItens.alertaQuantidadeSelecionada')
            })
            return 'R'
          }

        }
      },

      async buscaValoresEstoque(item){
        if(item.controlaEstoque) {
          const itensBloqueado = await EventoItensService.somaQuantidadeItemBloqueado(this.idEvento, this.objEditar.id ? this.objEditar.id : 0, item.id)

          const quantidadeEstoque = await EstoqueService.findByItemDeposito({idItem: item.id})

          const listagemQuantidadeEstoque = quantidadeEstoque.data.rows
          const listagemItensBloqueado = itensBloqueado.data

          this.itemDepositoEstoqueLista = listagemQuantidadeEstoque.map( el => {
            const retorno = {
              idDeposito: el.idDeposito,
              nomeDeposito: el.deposito,
              quantidadeEstoque: parseFloat(el.quantidadeEstoque)
            }

            if(this.objEditar.idDeposito){
              if(retorno.idDeposito == this.objEditar.idDeposito){
                this.itemDepositoSelecionado = [retorno]
              }
            }

            const bloqueados = listagemItensBloqueado.filter(elemento => elemento.Deposito && elemento.Deposito.id  === el.idDeposito)
            if(bloqueados && bloqueados.length > 0){
              retorno.quantidadeBloqueada = parseFloat(bloqueados[0].quantidadeBloqueada)
              retorno.quantidadeDisponivel = parseFloat(retorno.quantidadeEstoque) - parseFloat(retorno.quantidadeBloqueada)
            } else {
              retorno.quantidadeBloqueada = 0
              retorno.quantidadeDisponivel = retorno.quantidadeEstoque
            }
            return retorno
          })

          listagemItensBloqueado.forEach(el => {
            const itemDepositoEstoque = this.itemDepositoEstoqueLista.filter(elemento => {
              return el.Deposito && elemento.idDeposito === el.Deposito.id
            })
            if(!itemDepositoEstoque || itemDepositoEstoque.length === 0){
              const retorno = {
                idDeposito: el.idDeposito,
                nomeDeposito: el.nome,
                quantidadeEstoque: 0,
                quantidadeBloqueada: parseFloat(el.quantidadeBloqueada),
                quantidadeDisponivel: 0 - parseFloat(el.quantidadeBloqueada)
              }
              this.itemDepositoEstoqueLista.push(retorno)
            }
          })

          this.itemDepositoEstoqueLista = this.itemDepositoEstoqueLista.sort(function(a,b){
            if (a.nomeDeposito > b.nomeDeposito) {
              return 1;
            }
            if (a.nomeDeposito < b.nomeDeposito) {
              return -1;
            }
            return 0;
          })
          this.mostraQuantidadeEstoque = true
        } else {
          this.mostraQuantidadeEstoque = false
        }
      },
      calculaValorTotalEvento () {
        this.valorTotalEvento = 0
        this.valorCustoEvento = 0
        EventoItensService.somaValorVendaCusto(this.idEvento)
          .then( res => {
            this.valorTotalEvento = res.data.valorTotalEvento * 100 / 100
            this.valorCustoEvento = res.data.valorCustoEvento * 100 / 100
          })
      },
      novo () {
        this.objEditar = Object.assign({}, this.objPadrao)
        this.formulario = true
        this.editedIndex = -1
      },

      processaVisaoCliente() {
        this.visaoCliente = !this.visaoCliente
        if(this.visaoCliente){
          this.tempItems = []
          this.tempItems.push(...this.items)
          this.items = this.items.filter(element => !element.escondeVisaoCliente)
        }else{
          this.items = [...this.tempItems]
        }
      },

      editar (eventoItem) {
        this.editedIndex = this.items.indexOf(eventoItem)
        this.itemDepositoSelecionado = []
        EventoItensService.findById(eventoItem)
          .then(eventoItem => {
            this.objEditar = Object.assign({}, eventoItem.data)
            this.autoCompleteModel = this.objEditar.Item
            this.autoCompleteItens = [this.objEditar.Item]
            this.autoCompleteCampoBusca = this.objEditar.Item.nomeItem
            this.formulario = true
            this.isEditting = true
            this.valorUnitario = (parseFloat(eventoItem.data.valorTotalItem) + parseFloat(eventoItem.data.desconto || 0)) / parseFloat(eventoItem.data.quantidade)
            this.processaQuantidade(eventoItem.data.quantidade)
            this.buscaValoresEstoque(this.objEditar.Item)
          })
          .catch(error => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Ocorreu ao buscar o item do evento: \n ->' + error
            })
          })
      },

      closeDialog () {
        this.formulario = false
        this.isEditting = false
        this.objEditar = Object.assign({}, this.objPadrao)
        this.autoCompleteModel = null
        this.autoCompleteItens = []
        this.valorUnitario = 0
      },
      continueSaveEvent() {

        this.formulario = false
        this.isEditting = false

        if (this.editedIndex > -1) {
          this.atualizar(this.objEditar)
        } else {
          this.inserir(this.objEditar)
        }
        this.closeDialog()
      },
      async saveEvent () {

        this.objEditar.idItem = this.autoCompleteModel.id
        this.objEditar.idEvento = this.idEvento
        if(this.itemDepositoSelecionado && this.itemDepositoSelecionado.length > 0){
          this.objEditar.idDeposito = this.itemDepositoSelecionado[0].idDeposito
        }else{
          this.objEditar.idDeposito = null
        }

        const valida = await this.validaQuantidadeEstoque()

        if(valida === 'R' || valida === 'P'){
          return
        }

        this.continueSaveEvent()

      },

      remover () {
        const endereco = this.objEditar
        try {
          EventoItensService.delete(endereco).then(response => {
            this.$root.$emit('mostra_dialogo_alerta', {
              dialogoTitulo: this.$i18n.t('padrao_sucesso'),
              dialogoDescricao: this.$i18n.t('padrao_registro_removido_sucesso')
            })
            this.closeDialog()
            this.buscar()
          }).catch(error => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ':\n ->' + error
            })
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ':\n ->' + error
          })
        }
      },

      buscaItem (param) {
        this.autoCompleteLoading = true
        ItemService.findByParamAutoComplete(param, this.idEmpresaCliente)
          .then(items => {
            this.autoCompleteItens = items.data
          })
          .catch(error => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ':\n ->' + error
            })
          })
          .finally(() => {
            this.autoCompleteLoading = false
          })
      },
      filterAc (item, queryText, itemText) {
        return true
      },
      inserir (registro) {
        try {
          EventoItensService.insert(registro).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao')
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$i18n.t('padrao_sucesso'),
                dialogoDescricao: this.$i18n.t('padrao_registro_salvo_sucesso')
              })
              this.buscar()
            }
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: '' + error
          })
        }
      },
      atualizar (registro) {
        try {
          EventoItensService.update(registro).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao')
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$i18n.t('padrao_sucesso'),
                dialogoDescricao: this.$i18n.t('padrao_registro_atualizado_sucesso')
              })
              this.buscar()
            }
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': ' + error
          })
        }
      },
      buscar () {
        try {
          const param = {
            pagination: this.paginacao,
            idEvento: this.idEvento
          }
          this.loading = true
          if (!this.idEvento) {
            return
          }

          EventoItensService.findAll(param)
            .then(response => {
              if (!response) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao')
                })
                return
              } else if (response.error) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: '' + response.error
                })
                return
              }
              this.items = response.data.rows
              this.totalRegistros = response.data.count
              this.loading = false
              this.calculaValorTotalEvento()
            })
            .catch(err => {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': ' + err
              }
              )
            })
            .finally(() => {
              this.loading = false
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': ' + error
          })
          this.loading = false
        }
      }
    }
  }
</script>
